import React, {useState, useEffect} from 'react'

import Layout from '../components/layout'
import cssAcerca from '../styles/acercade.module.scss'

const mision = 'Ofrecer nuestros servicios de asesoría, consultoría y estudios en áreas especializadas relacionadas a la producción y perforación de pozos en la industria petrolera con la experiencia de nuestro equipo de trabajo, garantizando la calidad, profesionalismo y la confidencialidad en beneficio de nuestros clientes, empleados, accionistas y todos aquellos que forman parte de las comunidades en donde trabajamos y vivimos.';
const vision = 'Ser la empresa líder global en materia de servicios enfocados hacia el mercado energético.';
const valores = ' Los principales valores de INNOVA M&D, son la ética, confidencialidad, honestidad y el compromiso con nuestras partes interesadas, trabajando con pasión, calidad, creatividad e innovación que nos conduce hacia la mejora continua, demostrando liderazgo, inclusividad y trabajo en equipo.';


const Acerca = () => {

  const [msg, setMsg] = useState(0);
  useEffect(() => {

  })

  const bloque1 = () => {
    if(msg === 0){
      return {background: '#ffffff'}
    } else {
      return {}
    }
  }
  const bloque2 = () => {
    if(msg === 1){
      return {background: '#ffffff'}
    } else {
      return {}
    }
  }
  const bloque3 = () => {
    if(msg === 2){
      return {background: '#ffffff'}
    } else {
      return {}
    }
  }

  const atras = (e) => {
    e.preventDefault();
    if(msg !== 0) setMsg(msg-1);
    if(msg === 0) setMsg(2);
  }
  const adelante = (e) => {
    e.preventDefault();
    if(msg !== 2) setMsg(msg+1);
    if(msg === 2) setMsg(0);
  }

  return (
    <Layout className={cssAcerca.acerca}>
      <div className={cssAcerca.raiz}>
        <h2 className={cssAcerca.titulo}>{msg === 0 ? 'Misión': msg === 1 ? 'Visión': 'Valores'}</h2>
        <p className={cssAcerca.cuerpo}>{msg === 0 ? mision: msg === 1 ? vision: valores}</p>
        <div className={cssAcerca.scroll}>
          <div className={cssAcerca.flecha} onClick={atras}>⇠</div>
          <div className={cssAcerca.bloque} style={bloque1()}></div>
          <div className={cssAcerca.bloque} style={bloque2()}></div>
          <div className={cssAcerca.bloque} style={bloque3()}></div>
          <div className={cssAcerca.flecha} onClick={adelante}>⇢</div>
        </div>
      </div>
    </Layout>
  );
}
export default Acerca;